import React from 'react'



export const About = () => {
  return (
    <>
      
       <h1 >About US</h1>

    </>
  )
}
