import React from 'react';
import "./Card_1.css"
import { Carousel_1 } from "./slider/Carousel_1";
export const Card_1 = () => {
  return (
    <div>
      <div className="main_main">
        <div className="main_heading">
          <h1>OUR POPULAR PRODUCTS</h1>
        </div>
        <div className="main_body">
          <div className="main_half_body">
            <h5>| Consumer</h5>
            <Carousel_1 />
          </div>


        </div>
      </div>
    </div>
  )
}
