/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import "./presenting.css"
import Insight from '../../../../src/assets/presenting/insight.webp'
import Event from '../../../../src/assets/presenting/event.webp'
import Sol from '../../../../src/assets/presenting/solution.webp'
import News from '../../../../src/assets/presenting/news.webp'
import Video from '../../../../src/assets/presenting/video.jpg'

export const Presenting = () => {
    return (
        <>
            <section style={ { width: "100%", position: "" } }>
                <div className="Present">
                    <h2 className='title'>
                        PRODUCTS
                    </h2>

                    <div className='first_box'>
                        <div className='image1'><img src="https://t3.ftcdn.net/jpg/04/29/11/72/360_F_429117297_1ZqAUuJyeoCrRTzfMImhEVMakiP8spek.jpg" className='web_img' /></div>
                        <a href="" className='resource'>Insight</a>
                        <span>Data Center Cooling: The Key to Green Computing and a Low-Carbon Transition</span>
                        <div className="learn_btn3"> Learn More </div>
                    </div>

                    <div className='first_box'>
                        <div className='image1'> <img src="https://images.unsplash.com/photo-1561489396-888724a1543d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fGV2ZW50fGVufDB8fDB8fHww" alt="" className='web_img' /></div>
                        <a href="" className='resource'>Event</a>
                        <span>SupercomputingAsia 2024</span>

                        <div className="learn_btn3"> Learn More { }</div>

                    </div>

                    <div className='first_box'>
                        <div className='image1'>  <img src="https://media.istockphoto.com/id/1488294044/photo/businessman-works-on-laptop-showing-business-analytics-dashboard-with-charts-metrics-and-kpi.webp?b=1&s=170667a&w=0&k=20&c=BNF7gZ3XmCEf0_H8bUb6TLOvrDPVOmrEzbuu8FZrOao=" alt="" className='web_img' /></div>
                        <a href="" className='resource'>Solution</a>
                        <span>Single-Phase Immersion Cooling with Asperitas</span>
                        <div className="learn_btn3"> Learn More </div>
                    </div>

                    <div className='first_box'>
                        <div className='image1'> <img src="https://media.istockphoto.com/id/155284148/photo/digital-monitors-in-a-television-studio.webp?b=1&s=170667a&w=0&k=20&c=vntfsVG_pLX4pW-Zy6h1IJDkRob6dXqRYFcNj72ldWo=" alt="" className='web_img' /></div>
                        <a href="" className='resource'>News</a>
                        <span>Inviting the AI-powered 5G Era,  will Present Next-Gen Servers for AI/HPC, Telecom, and Green Computing Solutions at MWC 2024</span>
                        <div className="learn_btn3"> Learn More </div>
                    </div>

                    <div className='first_box'>
                        <div className='image1'>  <img src="https://images.pexels.com/photos/3379934/pexels-photo-3379934.jpeg?auto=compress&cs=tinysrgb&w=600" alt="" className='web_img' /></div>
                        <a href="" className='resource'>Video</a>
                        <span>Reshape the Game with </span>
                        <div className="learn_btn3"> Learn More </div>
                    </div>

                </div>
            </section>
        </>
    )
}
