// eslint - disable jsx - a11y / anchor - has - content
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import "./Footer_1.css"
import facebook from '../../assets/footer_images/bb902442d0880e99732aa1aada0c08de.jpg'
import video from '../../assets/footer_images/16630.png'
import insta from '../../assets/footer_images/instagram_icon_125245.png'
import inn from '../../assets/footer_images/linkedin-with-circle-icon-512x512-cvyrro5n.png'
import global from '../../assets/footer_images/global.png'

const Footer = () => {
    return (
        <>
            <div className="footer_wr">
                <div className="footer_section_padding">
                    <div className="footer_links_wr">
                        <div className="footer_link_div">
                            <h4>COMPANY</h4>
                            <a href=""><p>About Us</p></a>

                            <a href=""><p>Csr</p></a>




                            <a href=""><p>Contact Us</p></a>
                        </div>



                        <div className="footer_link_div">
                            <h4>Business</h4>
                            <a href=""><p>Desktop - Computers</p></a>

                            <a href=""><p>Laptop NoteBook</p></a>

                            <a href=""><p>All In One Desktop PC</p></a>

                            <a href=""><p>Display Systems</p></a>

                            <a href=""><p>Accessories</p></a>


                        </div>




                        <div className="footer_link_div">
                            <h4>ENTERPRISE</h4>
                            <a href=""><p>Workstation</p></a>

                            <a href=""><p>Server</p></a>

                            <a href=""><p>Storage</p></a>

                            <a href=""><p>Network Products</p></a>

                            <a href=""><p>Infrastructure Accessories</p></a>


                        </div>



                        <div className="footer_link_div">
                            <h4>Surveillance</h4>
                            <a href=""><p>Network Cameras</p></a>

                            <a href=""><p>Video Recorders and Workstations</p></a>

                            <a href=""><p>Software Solutions</p></a>

                            <a href=""><p>Camera Accessories</p></a>




                        </div>




                        <div className="footer_link_div">
                            <h4>SERVICE / SUPPORT</h4>
                            <a href=""><p>Consumer Products</p></a>

                            <a href=""><p>Enterprise Products</p></a>

                            <a href="">
                                <p>Surveillance Products</p>
                            </a>
                        </div>







                    </div>
                </div>
                <div className="icon_wr">
                    <div className="h4"> <h4>FOLLOW US</h4></div>
                    <div className='icons_wr'>
                        <i className='imagee'><img className='imageee' src={ video } alt="" /></i>
                        <i className='imagee'><img className='imageee' src={ insta } alt="" /></i>
                        <i className='imagee'><img className='imageee' src={ inn } alt="" /></i>
                        <i className='imagee'><img className='imageee' src={ facebook } alt="" /></i>
                    </div>

                </div>
                <hr className='line1' />


                <div className="last_index">


                    <div className='end'>
                        <img src={ global } alt="" />
                        <h3>Global (English)</h3>
                    </div>

                    <div className='end_right'>
                        <p> &#169; 2024 Latios Technology  Co., Ltd All rights reserved</p><hr />
                        <a href="">Terms of Use</a><hr />
                        <a href="">Privacy Policy</a><hr />
                        <a href="">Site map</a>
                    </div>
                </div>
            </div>


        </>
    )
}

export default Footer