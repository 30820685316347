import React from "react";
import { Presenting } from "./presenting/presenting";
import { Main_image } from "./main_image/Main_image";
import { Card_1 } from "./card_carousel/Card_1";

import { Cards_3 } from "./Cards_3/Cards_3";

export const Home = () =>{
    return(
        <>
            <Main_image/>
            <Presenting/>
            <Card_1/>
            <Cards_3/>
        </>
    )
}