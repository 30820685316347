import React, { useState } from 'react'
import "./Main_image.css";
import ba from "../../../assets/main-image/icons8-forward-30 (1).png";
import wa from "../../../assets/main-image/icons8-forward-30.png";

export const Main_image = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);

  return (
    <>
      <div >
        <div className='grad'></div>
        <div className="home_mainpic">
          <img
            className="home_mainpic_img"
            alt='alt'
            src="https://images.pexels.com/photos/234527/pexels-photo-234527.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
          ></img>

          <div className="home_floatpic">
            <div
              className="home_floatpic_1 home_hover_1"
              id="home_trans_1"
              onMouseEnter={ () => setIsHovered(true) }
              onMouseLeave={ () => setIsHovered(false) }
            >
              <p
                id="home_p_1"
                className={ `home_p_1 ${isHovered ? "hovered" : ""}` }
              >
                <div className="home_pic_info">Business product</div>
                { isHovered ? <img src={ ba } alt='alt' className="home_arrow" /> : <img src={ wa } alt='alt' className="home_arrow" /> }
              </p>
              <h6 className={ `home_small_info ${isHovered ? "_0" : ""}` }>Explore</h6>
              <img
                className="home_hover_1_img"
                alt='alt'
                src="https://images.unsplash.com/photo-1525547719571-a2d4ac8945e2?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8Y29tcHV0ZXJ8ZW58MHx8MHx8fDA%3D"
              />
            </div>
            <div
              className="home_floatpic_1 home_hover_2"
              onMouseEnter={ () => setIsHovered1(true) }
              onMouseLeave={ () => setIsHovered1(false) }
            >
              <p className={ `home_p_1 ${isHovered1 ? "hovered" : ""}` }>
                <div className="home_pic_info">Enterprice product</div>
                { isHovered1 ? <img src={ ba } alt='alt' className="home_arrow" /> : <img src={ wa } alt='alt' className="home_arrow" /> }
              </p>
              <h6 className={ `home_small_info ${isHovered1 ? "_1" : ""}` }>Explore</h6>
              <img
                className="home_hover_2_img"
                alt='alt'
                src="https://images.unsplash.com/photo-1550745165-9bc0b252726f?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzZ8fGNvbXB1dGVyfGVufDB8fDB8fHww"
              />
            </div>
            <div
              className="home_floatpic_1 home_hover_3"
              onMouseEnter={ () => setIsHovered2(true) }
              onMouseLeave={ () => setIsHovered2(false) }
            >
              <p className={ `home_p_1 ${isHovered2 ? "hovered" : ""}` }>
                <div className="home_pic_info">Surveilance product</div>
                { isHovered2 ? <img src={ ba } alt='alt' className="home_arrow" /> : <img src={ wa } alt='alt' className="home_arrow" /> }
              </p>
              <h6 className={ `home_small_info ${isHovered2 ? "_2" : ""}` }>Explore</h6>
              <img
                alt='alt'
                className="home_hover_3_img"
                src="https://images.unsplash.com/photo-1455894127589-22f75500213a?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDh8fGNvbXB1dGVyfGVufDB8fDB8fHww"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
