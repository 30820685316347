import React from "react";
import "./Navbar_1.css";
import { Link } from "react-router-dom";
import userB from "../../assets/nav-icons/icons8-user-64.png";
import userW from "../../assets/nav-icons/icons8-user-64 W.png"
import csrB from "../../assets/nav-icons/csr.png";
import csrW from "../../assets/nav-icons/csr W.png"
import searchB from "../../assets/nav-icons/icons8-search-50.png";
import searchW from "../../assets/nav-icons/icons8-search-50 W.png"
export const Navbar_1 = () => {
  window.onscroll = function () { myFunction(); };


  function myFunction() {
    const nav_1 = document.getElementById("navbar");
    const user = document.getElementById("user");
    const csr = document.getElementById("csr");
    const search = document.getElementById("search");
    const links = document.getElementsByClassName("links-css");
    const log = document.getElementById("logo_wr");

    const nav_css_1 = "background-color:ghostwhite; color : black;"
    const nav_css_2 = "background-color:transparent; color:White; "

    if (document.body.scrollTop > 10 || document.documentElement.scrollTop > 10) {
      nav_1.style.cssText = nav_css_1;
      log.style.color = "black";
      for (var i = 0; i < links.length; i++) {
        links[i].style.color = "black";// Set the desired color here
      }
      user.src = userB;
      csr.src = csrB;
      search.src = searchB;
    }
    else {
      nav_1.style.cssText = nav_css_2;
      log.style.color = "ghostwhite";
      for (i = 0; i < links.length; i++) {
        links[i].style.color = "ghostwhite";// Set the desired color here
      }
      user.src = userW;
      csr.src = csrW;
      search.src = searchW;
    }
  }









  return (
    <>
      {/* _wr :- without react */ }
      <div className="navbar_wr" id="navbar">
        <div className="logo_wr" id="logo_wr">
          <h1>Latios</h1>
        </div>
        <div className="nav-links_wr">
          <div>
            <Link className="links-css" to="/">
              Products
            </Link>
          </div>
          <div>
            <Link className="links-css" to="/about">
              Solutions
            </Link>
          </div>
          <div>
            <Link className="links-css" to="/about">
              Services
            </Link>
          </div>
          <div>
            <Link className="links-css" to="/about">
              Support
            </Link>
          </div>
          <div>
            <Link className="links-css" to="/about">
              Contact Us
            </Link>
          </div>
          <div>
            <Link className="links-css" to="/about">
              About us
            </Link>
          </div>
        </div>
        <div className="nav-icons_wr">
          <div>

            <Link to="/">

              <img className="image2" alt="alt" id="user" src={ userB } />
            </Link>
          </div>
          <div>

            <Link to="/">

              <img className="image2" alt="alt" id="csr" src={ csrB } />
            </Link>
          </div>
          <div>

            <Link to="/latios/src/About.js">

              <img className="image2" alt="alt" src={ searchB } id="search" />
            </Link>
          </div>
        </div>
      </div>

    </>
  );
};