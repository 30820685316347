import React, { useState } from "react";
import "./Carousel_1.css";
import game from '../../../../assets/gaming.png'

import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";

export const Carousel_1 = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);
  const [isHovered5, setIsHovered5] = useState(false);

  return (
    <>
      <Carousel>
        <Carousel.Item interval={ 1000 }>
          <div className="slider_body">
            <div
              className="slider_card"
              onMouseEnter={ () => setIsHovered(true) }
              onMouseLeave={ () => setIsHovered(false) }
            >
              <img alt='alt' src={ game }></img>
              <h6 className={ `slider_card_h5 ${isHovered ? "_0" : ""}` }>
                Gaming pc
              </h6>
            </div>
            <div
              className="slider_card"
              onMouseEnter={ () => setIsHovered1(true) }
              onMouseLeave={ () => setIsHovered1(false) }
            >
              <img alt='alt' src={ game }></img>
              <h6 className={ `slider_card_h5 ${isHovered1 ? "_1" : ""}` }>
                Gaming pc
              </h6>
            </div>
            <div
              className="slider_card"
              onMouseEnter={ () => setIsHovered2(true) }
              onMouseLeave={ () => setIsHovered2(false) }
            >
              <img alt='alt' src={ game }></img>
              <h6 className={ `slider_card_h5 ${isHovered2 ? "_2" : ""}` }>
                Gaming pc
              </h6>
            </div>
            <div
              className="slider_card"
              onMouseEnter={ () => setIsHovered3(true) }
              onMouseLeave={ () => setIsHovered3(false) }
            >
              <img alt='alt' src={ game }></img>
              <h6 className={ `slider_card_h5 ${isHovered3 ? "_3" : ""}` }>
                Gaming pc
              </h6>
            </div>
            <div
              className="slider_card"
              onMouseEnter={ () => setIsHovered4(true) }
              onMouseLeave={ () => setIsHovered4(false) }
            >
              <img alt='alt' src={ game }></img>
              <h6 className={ `slider_card_h5 ${isHovered4 ? "_4" : ""}` }>
                Gaming pc
              </h6>
            </div>
            <div
              className="slider_card"
              onMouseEnter={ () => setIsHovered5(true) }
              onMouseLeave={ () => setIsHovered5(false) }
            >
              <img alt='alt' src={ game }></img>
              <h6 className={ `slider_card_h5 ${isHovered5 ? "_5" : ""}` }>
                Gaming pc
              </h6>
            </div>

          </div>
        </Carousel.Item>
        <Carousel.Item interval={ 500 }>
          <div className="slider_body">
            <div
              className="slider_card"
              onMouseEnter={ () => setIsHovered(true) }
              onMouseLeave={ () => setIsHovered(false) }
            >
              <img alt='alt' src={ game }></img>
              <h6 className={ `slider_card_h5 ${isHovered ? "_0" : ""}` }>
                Gaming pc
              </h6>
            </div>
            <div
              className="slider_card"
              onMouseEnter={ () => setIsHovered1(true) }
              onMouseLeave={ () => setIsHovered1(false) }
            >
              <img alt='alt' src={ game }></img>
              <h6 className={ `slider_card_h5 ${isHovered1 ? "_1" : ""}` }>
                Gaming pc
              </h6>
            </div>
            <div
              className="slider_card"
              onMouseEnter={ () => setIsHovered2(true) }
              onMouseLeave={ () => setIsHovered2(false) }
            >
              <img alt='alt' src={ game }></img>
              <h6 className={ `slider_card_h5 ${isHovered2 ? "_2" : ""}` }>
                Gaming pc
              </h6>
            </div>
            <div
              className="slider_card"
              onMouseEnter={ () => setIsHovered3(true) }
              onMouseLeave={ () => setIsHovered3(false) }
            >
              <img alt='alt' src={ game }></img>
              <h6 className={ `slider_card_h5 ${isHovered3 ? "_3" : ""}` }>
                Gaming pc
              </h6>
            </div>
            <div
              className="slider_card"
              onMouseEnter={ () => setIsHovered4(true) }
              onMouseLeave={ () => setIsHovered4(false) }
            >
              <img alt='alt' src={ game }></img>
              <h6 className={ `slider_card_h5 ${isHovered4 ? "_4" : ""}` }>
                Gaming pc
              </h6>
            </div>
            <div
              className="slider_card"
              onMouseEnter={ () => setIsHovered5(true) }
              onMouseLeave={ () => setIsHovered5(false) }
            >
              <img alt='alt' src={ game }></img>
              <h6 className={ `slider_card_h5 ${isHovered5 ? "_5" : ""}` }>
                Gaming pc
              </h6>
            </div>

          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="slider_body">
            <div
              className="slider_card"
              onMouseEnter={ () => setIsHovered(true) }
              onMouseLeave={ () => setIsHovered(false) }
            >
              <img alt='alt' src={ game }></img>
              <h6 className={ `slider_card_h5 ${isHovered ? "_0" : ""}` }>
                Gaming pc
              </h6>
            </div>
            <div
              className="slider_card"
              onMouseEnter={ () => setIsHovered1(true) }
              onMouseLeave={ () => setIsHovered1(false) }
            >
              <img alt='alt' src={ game }></img>
              <h6 className={ `slider_card_h5 ${isHovered1 ? "_1" : ""}` }>
                Gaming pc
              </h6>
            </div>
            <div
              className="slider_card"
              onMouseEnter={ () => setIsHovered2(true) }
              onMouseLeave={ () => setIsHovered2(false) }
            >
              <img alt='alt' src={ game }></img>
              <h6 className={ `slider_card_h5 ${isHovered2 ? "_2" : ""}` }>
                Gaming pc
              </h6>
            </div>
            <div
              className="slider_card"
              onMouseEnter={ () => setIsHovered3(true) }
              onMouseLeave={ () => setIsHovered3(false) }
            >
              <img alt='alt' src={ game }></img>
              <h6 className={ `slider_card_h5 ${isHovered3 ? "_3" : ""}` }>
                Gaming pc
              </h6>
            </div>
            <div
              className="slider_card"
              onMouseEnter={ () => setIsHovered4(true) }
              onMouseLeave={ () => setIsHovered4(false) }
            >
              <img alt='alt' src={ game }></img>
              <h6 className={ `slider_card_h5 ${isHovered4 ? "_4" : ""}` }>
                Gaming pc
              </h6>
            </div>
            <div
              className="slider_card"
              onMouseEnter={ () => setIsHovered5(true) }
              onMouseLeave={ () => setIsHovered5(false) }
            >
              <img alt='alt' src={ game }></img>
              <h6 className={ `slider_card_h5 ${isHovered5 ? "_5" : ""}` }>
                Gaming pc
              </h6>
            </div>

          </div>
        </Carousel.Item>

      </Carousel>
    </>
  );
};
