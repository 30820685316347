import React from 'react'

import {
  BrowserRouter as Router,
  Routes,
  Route,
 
} from "react-router-dom";
import { About } from './About/About';
import { Home } from './Home_Page/Home';
import { Navbar_1 } from './Navbar/Navbar_1';
import Footer_1 from './Footer/Footer_1';



export default function App() {
  return (
    <Router>
        <Navbar_1/>
        <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/about" element={<About />} />
        </Routes>
        <Footer_1/>
    </Router>
  )
}
